import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../utils/AuthContext';
import { getCurrentUser } from 'aws-amplify/auth';

function AuthCallback() {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  useEffect(() => {
    async function handleAuthResponse() {
      try {
        const user = await getCurrentUser();
        setUser(user);
        navigate('/'); // Redirect to a protected route or home
      } catch (error) {
        console.error('Error during authentication:', error);
        // Handle or redirect to an error page
        navigate('/error');
      }
    }

    handleAuthResponse();
  }, [navigate]);

  return <div>Processing authentication...</div>;
}

export default AuthCallback;
