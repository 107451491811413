import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../utils/AuthContext';
import { Amplify } from 'aws-amplify';
import { awsconfig } from '../utils/AmplifyConfig';
import { signInWithRedirect, getCurrentUser } from 'aws-amplify/auth';

Amplify.configure(awsconfig);

function signIn() {
    signInWithRedirect();
}

const Login = () => {
    const {authenticatedUser} = useContext(AuthContext);
    const navigate = useNavigate();

    console.debug(`From Login: authenticatedUser in context: ${JSON.stringify(authenticatedUser)}`)

    const isAuthenticated = getCurrentUser()
        .then((argument) => {
            console.debug(`From Login: isAuthenticated: ${JSON.stringify(argument)}`)
            //Is already logged in. Navigate to default route
            navigate('/');
            return 'Already loggin in. Redirecting...';
        })
        .catch((argument) => {
            console.debug(`From Login: Could not find authenticated user: ${JSON.stringify(argument)}. Signing in...`)
            signIn();
            return 'Redirecting to login screen...';
        });


    return <div>Logging in...</div>;
};

export default Login;
