import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelectedConsumer } from '../App';

function startLoadingSpinner(elementId) {
  console.debug(`Starting loading spinner for DIV#${elementId}`)
  // Check if the spinner already exists, and if so, do nothing
  if (document.getElementById('spinner-' + elementId)) return;

  var spinner = document.createElement("div");
  spinner.className = "spinner";
  spinner.innerHTML = '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div>';
  spinner.id = 'spinner-' + elementId;  // Assign a unique id to the spinner

  // Append the spinner to the specified div
  document.getElementById(elementId).appendChild(spinner);
}

function stopLoadingSpinner(elementId) {
  console.debug(`Stopping loading spinner for DIV#${elementId}`)
  var spinner = document.getElementById('spinner-' + elementId);
  
  // If the spinner exists, remove it
  if (spinner) {
      spinner.parentNode.removeChild(spinner);
  }
}

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function SaveMoney({ onConsumerChange }) {
  const { selectedConsumer } = useSelectedConsumer();
  const [billingData, setBillingData] = useState('');

  useEffect(() => {
    if (selectedConsumer) {
      startLoadingSpinner('billingBlock');
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/bills?ignoreOrigin=true`)
        .then(response => {
          //Sort txns by date
          response.data.transactions.sort((a, b) => {
            let dateA = new Date(a.executionDateTime);
            let dateB = new Date(b.executionDateTime);
            
            //return dateA - dateB;  // For ascending order
            return dateB - dateA; // Uncomment this for descending order
          });
          setBillingData(response.data);
        })
        .catch(error => {
          console.error('Error fetching billing data:', error);
        })
        .finally(() => {
          stopLoadingSpinner('billingBlock');
        });
    }
  }, [selectedConsumer]);

  return (
    <div className="contentBody" id='billingBlock'>
      <h2>Save Money - {selectedConsumer}</h2>

      <h3>💥Freebies! Here are some easy ways to reduce your electricity bills without needing to adjust your behaviour</h3>
      <ul>
        <li>With your usage profile, switching to ACME Saver plan (4 ⭐) would save you around $354 per year. See our <a href=''>plan comparison tool</a> for more details</li>
        <li>You are currently using a tariff structure with a controlled load that runs a lot of the day (controlled load 2), and your controlled load usage is high. Changing this to a controlled load tariff that runs for less time (controlled load 1), it is estimated that you would save around $234 per year.</li>
        <li>You are currently using a tariff structure with a controlled load, and your plan is charging you for that connection, but you are not using any energy from that circuit. Removing the controlled load from your tariff or changing to a plan that doesn't charge you for it will save you around $60 per year.</li>
        <li>You are currently using a tariff structure that charges a flate rate for every kWh of electricity you use, regardless of the time of day that you use it. With your usage patterns, you could change to a different tariff structure called time-of-day tariff which charges difference rates depending on the time of day. Changing this and switching to the ACME TOU plan would save you $243 per year.</li>
      </ul>

      <h3>These ones require a change in behaviour from you and maybe an investment. Apparently you only need to repeat something 7 times to make it a habbit 😉</h3>
      <h3>For everyone</h3>
      <ul>
        <li>Use LED lights - you're not still using halogen, compact fluro or even incandesent lights still are you??? 🫠 With the prices of LED lights (even the 'smart' ones) and the ease of installation, there really is no excuse these days. Come on...</li>
        <li>Turn down the A/C - we all want to be comfortable, but that comes at a cost. Heating uses more energy than cooling, so put on an extra layer in winter before you turn on the reverse cycle A/C. Summer in most part of Aus is difficult, but we could all probably ease back on the A/C by a degree or two.</li>
        <li>Review your fridge - fridges are always on, 24x7. So they can be a culprit. How much energy does yours use??? Check the label or look it up online. If it uses more than X kWh per L of volume, then consider an investment to get a new, energy efficient one.</li>
        <li>Review your electric hot water system - this is another appliance that is energy hungry and is used every single day. Check the thermostat and dial it down a ntoch or two. Is the system way too big for the size of the house? Is it old? If you can, replace your electric tank with a heat-pump hot water system. They will save you on average X% compared to your electric. You are in a postcode that is currently eligible for government rebates, making the payback period Y years for a house your size. </li>
        <li>What other appliances do you run all day? Some common culprits are computers, gaming consoles, stereos, televisions, etc... If they don't need to be on, turn them off when you're not using them. You might be suprised with how much this can save you.</li>
        <li>Pool pumps, slab heating (lucky you!!!) - do they need to be running as long as they do? These guys will cause havoc with your bills, so consider reducing how long they are running for and check to make sure they run during the cheapest electricity period (if you are on a time-of-use tariff structure).</li>
      </ul>

      <h3>For those with solar</h3>
      <ul>
        <li>
          By far the biggest money gains available from solar is from the savings, not from the feed-in tariff revenue. These gains are only amplified as energy prices go up and feed-in tariff prices go down. So here are a list of things that you should be doing between the hours of 10am and 3pm to maximise those savings:
          <ul>
            <li>Washing - use your machine's timer to try and have the machine run during bright daylight</li>
            <li>Dishwashing - use your machine's timer to try and run the dishwasher during sunny hours</li>
            <li>Hot water - have a sparky install a timer for your electric hot water system so that it heats up during the middle of the day rather than overnight</li>
            <li>Dryer - the best solar dryer is a clothesline, but next to that try to run your tumble dryer during the day when the sun is shining bright</li>
            <li>Pool pump - set the timer to run during the daylight hours. If it needs longer, then make use if your plan's off-peak periods (for those on TOU plan).</li>
            <li>Electric Vehicle - if you're at home during the day and you have an EV, make sure it is plugged in during the daylight hours.</li>
            <li>Water pumps - if you have a garden fountain or you have a water tank with a pressure pump, try to find ways for these to operate mainly during daylight hours only.</li>
            <li>Etc...</li>
          </ul>
          Any excess solar energy will be sold to the grid, and this is where having the right plan will help maximise the savings available. After a few months of your new day-time behaviour, we will check to see if there is a plan better suited to your new patterns and let you know what we find.
        </li>
        <li>With that in mind, the way to use as much of your own solar as possible is to store it in a battery 🔋. Then you can use your energy any time of the day. The downside is that they are not cheap. However, with the right setup and some behaviour changes, you can increase your cashflow position by around $X per week, making for a Y year payback period. Our solar + battery simulator reccomends the following 4.5 ⭐ system and a change to the ACME Battery plan which will result in a payback period of Z years for you, well within the equipment's warrenty period of Y years. See our <a href=''>solar & battery simulation tool</a> for more details.</li>
      </ul>

      <h3>For those with solar and a battery</h3>
      <ul>
        <li>Consider a wholesale market plan, such as Amber Electric</li>
        <li>Consider participating in a Virtual Power Plant (VPP)</li>
      </ul>
    </div>
  );
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export default SaveMoney;
