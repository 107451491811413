import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const isAuthenticated = fetchAuthSession()
      .then((userSession) => {
        console.debug(`From authContext: isAuthenticated: ${JSON.stringify(userSession)}`)
        let userAttributes = userSession.tokens.idToken.payload;
        let user = {
          username: userAttributes["cognito:username"],
          userId: userSession.userSub,
          attributes: userAttributes
        }
        const adminEmails = ['matt@automised.energy'];
        if (adminEmails.includes(user.attributes.email)) {
          user.isAdmin = true;
        } else {
          user.isAdmin=false;
        }
        setUser(user)
        return true;
      })
      .catch((authenticatedUser) => {
        console.debug(`From protectedRoute: Could not find authenticated user: ${JSON.stringify(authenticatedUser)}. Signing in...`)
        setUser(undefined);
        return false;
      });
  }, []);

  useEffect(() => {
    if (!user) {
      setUserProfile(null);
      return;
    }

    //Get the user profile details
    axios.get(`${process.env.REACT_APP_API_URL}consumers/profile?ignoreOrigin=true`)
      .then(response => {
        setUserProfile(response.data);
      })
      .catch(error => {
        setUserProfile(null);
        console.error('Error fetching user profile:', error);
      })
      .finally(() => {
      });


  }, [user]);

  return (
    <AuthContext.Provider value={{ user, setUser, userProfile }}>
      {children}
    </AuthContext.Provider>
  );
};
