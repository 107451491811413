import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelectedConsumer } from '../App';

function startLoadingSpinner(elementId) {
  console.debug(`Starting loading spinner for DIV#${elementId}`)
  // Check if the spinner already exists, and if so, do nothing
  if (document.getElementById('spinner-' + elementId)) return;

  var spinner = document.createElement("div");
  spinner.className = "spinner";
  spinner.innerHTML = '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div>';
  spinner.id = 'spinner-' + elementId;  // Assign a unique id to the spinner

  // Append the spinner to the specified div
  document.getElementById(elementId).appendChild(spinner);
}

function stopLoadingSpinner(elementId) {
  console.debug(`Stopping loading spinner for DIV#${elementId}`)
  var spinner = document.getElementById('spinner-' + elementId);
  
  // If the spinner exists, remove it
  if (spinner) {
      spinner.parentNode.removeChild(spinner);
  }
}

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function CurrentPlan({ onConsumerChange }) {
  const { selectedConsumer, selectedServicePoint, selectedConsumerAccount } = useSelectedConsumer();
  const [planData, setPlanData] = useState('');

  useEffect(() => {
    if (selectedServicePoint) {
      startLoadingSpinner('planBlock');
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/accounts/${selectedConsumerAccount}/service-points/${selectedServicePoint}/plan?ignoreOrigin=true`)
        .then(response => {
          setPlanData(response.data);
        })
        .catch(error => {
          setPlanData('Error fetching plan details. See server logs for more details');
          console.error('Error fetching plan data:', error);
        })
        .finally(() => {
          stopLoadingSpinner('planBlock');
        });
    } else {
      setPlanData(`Select a service-point/NMI`)
    }
  }, [selectedServicePoint]);

  return (
    <div className="contentBody" id='planBlock'>
      <h2>Current Plan - {selectedConsumer}</h2>
      <h3>All plan prices are exclusive of GST</h3>

      <pre>
        {JSON.stringify(planData, null, 2)}
      </pre>
    </div>
  );
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export default CurrentPlan;
