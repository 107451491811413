import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelectedConsumer } from '../App';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(...registerables);
Chart.register(zoomPlugin);

function startLoadingSpinner(elementId) {
  console.debug(`Starting loading spinner for DIV#${elementId}`)
  // Check if the spinner already exists, and if so, do nothing
  if (document.getElementById('spinner-' + elementId)) return;

  var spinner = document.createElement("div");
  spinner.className = "spinner";
  spinner.innerHTML = '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div>';
  spinner.id = 'spinner-' + elementId;  // Assign a unique id to the spinner

  // Append the spinner to the specified div
  document.getElementById(elementId).appendChild(spinner);
}

function stopLoadingSpinner(elementId) {
  console.debug(`Stopping loading spinner for DIV#${elementId}`)
  var spinner = document.getElementById('spinner-' + elementId);
  
  // If the spinner exists, remove it
  if (spinner) {
      spinner.parentNode.removeChild(spinner);
  }
}

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function UsageHistory({ onConsumerChange }) {
  const { selectedConsumer, selectedAccount, selectedServicePoint } = useSelectedConsumer();
  const [usageReadings, setUsageReadings] = useState('');
  const [dailyProfileData, setDailyProfileData] = useState({});
  const [dailyProfileToggle, setDailyProfileToggle] = useState('weekday');
  const [dailyProfileSeasonToggle, setDailyProfileSeasonToggle] = useState('current');
  const [usageHistory, setUsageHistory] = useState({});
  const [usageHistoryChart, setUsageHistoryChart] = useState(null);
  const [dailyProfileChart, setDailyProfileChart] = useState(null);
  const [averageUsageChart, setAverageUsageChart] = useState(null);
    
  var runCount = 0;

  useEffect(() => {
    runCount++;

    // Initialize chart with empty data
    if (runCount == 1) {
      initialiseUsageHistoryChart(usageHistory);
      initialiseDailyProfileChart(dailyProfileData);
      initialiseAverageUsageChart(dailyProfileData);
    }
  }, []);

  useEffect(() => {
    if (selectedConsumer) {
      //Raw data
      startLoadingSpinner('rawDataBlock');
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/usage/reads?unitOfMeasure=KWH&servicePointId=${selectedServicePoint}&ignoreOrigin=true`) //?accountId=abc123 for filtering by account
        .then(response => {
          //Sort txns by date
          response.data.sort((a, b) => {
            let dateA = new Date(a.executionDateTime);
            let dateB = new Date(b.executionDateTime);
            
            //return dateA - dateB;  // For ascending order
            return dateB - dateA; // Uncomment this for descending order
          });
          setUsageReadings(response.data);
        })
        .catch(error => {
          console.error('Error fetching usage data:', error);
        })
        .finally(() => {
          stopLoadingSpinner('rawDataBlock');
        });

      //Usage history
      startLoadingSpinner('usageHistoryBlock');
        axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/usage/history?unitOfMeasure=KWH&servicePointId=${selectedServicePoint}&ignoreOrigin=true`) //?accountId=abc123&interval=day&periodStart=YYY-MM-DD&periodEnd=YYY-MM-DD for filtering by account
          .then(response => {
            setUsageHistory(response.data);
          })
          .catch(error => {
            setUsageHistory(undefined);
            console.error('Error fetching usage data:', error);
          })
          .finally(() => {
            stopLoadingSpinner('usageHistoryBlock');
          });
  
      //Usage profile
      startLoadingSpinner('usageProfileBlock');
        axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/usage/profiles?servicePointId=${selectedServicePoint}&ignoreOrigin=true`) //?accountId=abc123&periodStart=YYY-MM-DD&periodEnd=YYY-MM-DD for filtering by account
          .then(response => {
            setDailyProfileData(response.data);
          })
          .catch(error => {
            setDailyProfileData(undefined);
            console.error('Error fetching usage data:', error);
          })
          .finally(() => {
            stopLoadingSpinner('usageProfileBlock');
          });
    }
  }, [selectedServicePoint]);

  useEffect(() => {
    updateUsageHistoryChart(usageHistory);
  }, [usageHistory])

  useEffect(() => {
    console.debug(`dailyProfileData or dailyProfileToggle or dailyProfileSeasonToggle changed`);
    if (dailyProfileData?.profiles) {
      const targetSeasonProfile = dailyProfileData?.profiles[dailyProfileSeasonToggle];
      const targetProfileByHourData = targetSeasonProfile[dailyProfileToggle]?.byHour;

      //Find extremeties
        let absMax = 0
        //Loop through each seasonal profile looking for extremes
        for(let season in dailyProfileData.profiles) {
          // Loop through each weekday/weekend profile looking for extremes
          for(let dayType of ['weekday', 'weekend']) {
            if (dailyProfileData.profiles[season][dayType].days > 0) {
              let byHourData = dailyProfileData.profiles[season][dayType].byHour;
              //Aggregate/stack the imports
              let stackedImports = byHourData.generalImport.map((generalImportAmount, index) => generalImportAmount + (byHourData.clImport ? byHourData.clImport[index] : 0)); //Add general and CL usage values for each hour to get the stacked total for each hour
              let absMaxImports = Math.max(...stackedImports);
              //Find max exports
              let absMaxExports = byHourData.export ? Math.abs(Math.min(...byHourData.export)) : 0;
              //Find the absMax of imports and the absMax of exports
              absMax = Math.max(absMax, absMaxImports, absMaxExports);
            }
          }
        }

        //Some label info
        let dateRange = targetSeasonProfile.startDate + ' - ' + targetSeasonProfile.endDate;
        //Update to daily pattern chart
        updateDailyProfileChart(
        [
            {label: "kWh export", data: targetProfileByHourData.export, backgroundColor: 'green', absMax: absMax, days: targetSeasonProfile[dailyProfileToggle].days, dateRange },
            {label: "kWh import", data: targetProfileByHourData.generalImport, backgroundColor: 'red', absMax: absMax, days: targetSeasonProfile[dailyProfileToggle].days, dateRange},
            {label: "kWh import (CL)", data: targetProfileByHourData.clImport, backgroundColor: 'orange', absMax: absMax, days: targetSeasonProfile[dailyProfileToggle].days, dateRange},
        ]);

        //setClProportion(usageData.dailyByHour[dailyPatternToggle].avgDailyClImport / (usageData.dailyByHour[dailyPatternToggle].avgDailyClImport + usageData.dailyByHour[dailyPatternToggle].avgDailyImport));
    } else {
        updateDailyProfileChart([]);
    }

    if (dailyProfileData?.averages) {
      updateAverageUsageChart([
        {label: "kWh export", data: [Math.abs(dailyProfileData.averages.perYear.export || 0)], backgroundColor: 'green'},
        {label: "kWh import", data: [dailyProfileData.averages.perYear.generalImport || 0], backgroundColor: 'red'},
        {label: "kWh import (CL)", data: [dailyProfileData.averages.perYear.clImport || 0], backgroundColor: 'orange'},
      ]);
    } else {
        updateAverageUsageChart([]);
    }

  }, [dailyProfileData, dailyProfileToggle, dailyProfileSeasonToggle])

  const initialiseUsageHistoryChart = (data) => {
    console.debug(`Initialising usage history chart...`);
    // Your chart initialisation code here
    // Use chartRef.current instead of 'myChart'
    // Destroy the existing chart if it exists
    if (usageHistoryChart) {
      usageHistoryChart.destroy();
    }

    let datasets = [];
    let labels = [];
    let canvas = document.getElementById('usageHistoryChart');
    let ctx = canvas.getContext('2d');

    const newChart = new Chart(ctx, {
        type: 'bar',
        data: { labels, datasets },
        options: {
            responsive: true,
            scales: {
                x: { 
                  stacked: true,
                  type: 'time',
                  time: {
                    unit: 'day'
                  }
                 },
                y: {
                    stacked: true,
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                            return value.toFixed(2) + 'kWh';
                        }
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Daily usage'
                },
                zoom: {
                  zoom: {
                    wheel: {
                      enabled: true,
                      modifierKey: 'ctrl'
                    },
                    pinch: {
                      enabled: true
                    },
                    mode: 'x',
                  },
                  pan: {
                    enabled: true,
                    mode: 'x'
                  }
                }
            }
        }
  });
    setUsageHistoryChart(newChart);

    // Cleanup function
    return () => {
        newChart.destroy();
    };
  };

  const updateUsageHistoryChart = (data) => {
    console.debug(`updateBillingCostHistoryChart called`)
    const targetChart = usageHistoryChart;

    if (targetChart) {
      if (!data) {
        targetChart.data.datasets = [];
        targetChart.data.labels = [];
        targetChart.update();
        return;
      }

      let byDayArray = data.byDayArray;
      byDayArray.sort();

      //Get the data in a format for use by chartjs
      // Extract the dates
      const dates = byDayArray.map(item => item[0]);

      // Extract the unique keys from the data object (not all objects have the same keys, so can't just read the first element)
      let keySet = new Set();
      byDayArray.forEach(day => {
        for (let key in day[1]) {
          if (key !== 'byHour') { //'byHour' is not a key
            keySet.add(key);
          }
        }
      })
      const keys = Array.from(keySet);

      // Generate the datasets
      const datasets = keys.map(key => {
        let color, order;
        switch(key) {
          case 'generalImport': {
            color = 'rgba(255, 0, 0, 0.5)';
            order = 1;
          }; break;
          case 'clImport': {
            color = 'rgba(255, 165, 0, 0.5)';
            order = 100;
          }; break;
          case 'export': {
            color = 'rgba(0, 128, 0, 0.5)';
            order = 1;
          }; break;
        }
        return {
          label: key.charAt(0).toUpperCase() + key.slice(1),  // Capitalize the key for the label
          data: byDayArray.map(item => item[1][key]),
          backgroundColor: color,
          order: order
          // Add your color, etc. here
        };
      });


      //Moving average
      let averages = [];
      let windowSize = 14; //N day window for averaging
      for (let i = 0; i < byDayArray.length + 1; i++) {
          let lowerBound = 0, upperBound = i + (windowSize/2);
          if ((i - (windowSize/2)) < 0) lowerBound = 0;
          if ((i + (windowSize/2)) > byDayArray.length) upperBound = byDayArray.length;
          let entriesCount = upperBound - lowerBound;
          let currentWindow = byDayArray.slice(lowerBound, upperBound);
          let sum = currentWindow.reduce((total, current) => {
            let netCostForDay = 0;
            Object.entries(current[1]).forEach(([category, categoryUsage]) => {
              if (category != 'byHour') netCostForDay += categoryUsage;
            })
  
            return total + netCostForDay
          }, 0);
          averages.push(sum / entriesCount);
      }

      datasets.push({
        label: 'Moving Avg',
        data: averages,
        type: 'line',
        fill: false,
        order: 0,
//          borderColor: 'rgba(0,0,255,0.5)',  // Choose your own color
//          backgroundColor: 'rgba(0,0,255,0.5)',
        tension: 0.4
    });

      targetChart.data.datasets = datasets;
      targetChart.data.labels = dates;
      targetChart.update();
    }
  };
  
  const initialiseDailyProfileChart = (data) => {
    console.debug(`Initialising daily profile chart...`);
    // Your chart initialisation code here
    // Use chartRef.current instead of 'myChart'
    // Destroy the existing chart if it exists
    if (dailyProfileChart) {
        dailyProfileChart.destroy();
    }

    let datasets = [];
    let labels = [];

    const newChart = new Chart(document.getElementById("dailyProfileChart"), {
        type: 'bar',
        data: { labels, datasets },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: { stacked: true },
                y: {
                    stacked: true,
                    //min: -5,
                    //max: 5,
                    suggestedMin: -5,
                    suggestedMax: 5,
                    ticks: {
                        stepSize: 0.1, // Change this to control the granularity
                        //beginAtZero: true,
                        //max: 5,
                        //min: -5
                        callback: function(value, index, values) {
                            return value.toFixed(2);
                        }
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Daily usage profile, by hour'
                }
            }
        }
  });
    setDailyProfileChart(newChart);

    // Cleanup function
    return () => {
        newChart.destroy();
    };
  };

  const updateDailyProfileChart = (dataSets) => {
    console.debug(`updateDailyProfileChart called`)
    const targetChart = dailyProfileChart;
    if (targetChart) {
        // Your chart update code here
        const labels = ['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm'];
        const datasets = dataSets;

        targetChart.data.datasets = datasets;
        targetChart.data.labels = labels;
        //if (dataSets[0]) targetChart.options.plugins.title.text = dailyPatternToggle;
        targetChart.options.plugins.title.text = datasets[0].days + ' days from ' + datasets[0].dateRange;
        targetChart.options.scales.y.suggestedMin = datasets[0] ? -datasets[0].absMax : 0; //Make symetrical y-axis
        targetChart.options.scales.y.suggestedMax = datasets[0] ? datasets[0].absMax : 0; //Make symetrical y-axis
        targetChart.update();
    }
  };

  const initialiseAverageUsageChart = (data) => {
    console.debug(`Initialising average usage chart...`);
    // Your chart initialisation code here
    // Use chartRef.current instead of 'myChart'
    // Destroy the existing chart if it exists
    if (averageUsageChart) {
        averageUsageChart.destroy();
    }

    let datasets = [];
    let labels = [];

    const newChart = new Chart(document.getElementById("usageSummaryChart"), {
        type: 'bar',
        data: { labels, datasets },
        options: {
            responsive: true,
            //maintainAspectRatio: false,
            //scales: {
                //x: { stacked: false },
                //y: {
                    //stacked: false,
                //    ticks: {
                        //stepSize: 0.1, // Change this to control the granularity
                        //callback: function(value, index, values) {
                        //    return value.toFixed(2);
                        //}
                //    }
                //}
            //},
            plugins: {
                title: {
                    display: true,
                    text: 'Average Annual Usage'
                }
            }
        }
  });
    setAverageUsageChart(newChart);

    // Cleanup function
    return () => {
        newChart.destroy();
    };
  };

  const updateAverageUsageChart = (dataSets) => {
    console.debug(`updateAverageUsageChart called`)
    const targetChart = averageUsageChart;
    if (targetChart) {
        // Your chart update code here
        const labels = ['Average usage'];
        const datasets = dataSets;

        targetChart.data.datasets = datasets;
        targetChart.data.labels = labels;
        //if (dataSets[0]) targetChart.options.plugins.title.text = dailyPatternToggle;
        //targetChart.options.scales.y.suggestedMin = datasets[0] ? -datasets[0].absMax : 0; //Make symetrical y-axis
        //targetChart.options.scales.y.suggestedMax = datasets[0] ? datasets[0].absMax : 0; //Make symetrical y-axis
        targetChart.update();
    }
  };
   
  return (
    <div className="contentBody" id='usageBlock'>
      <h2>Usage History - {selectedConsumer}</h2>

      <div id="usageHistoryBlock" className="chartBox">
        <h3>Daily usage</h3>
        <canvas id="usageHistoryChart"></canvas>
      </div>

      <div id="usageProfileBlock" className="chartBox">
        <h3>Usage profile</h3>
        <div className="toggleControl">
            <label>
            <input
                type="radio"
                value="weekday"
                checked={dailyProfileToggle === 'weekday'}
                onChange={(e) => setDailyProfileToggle(e.target.value)}
            />
            Weekday
            </label>

            <label>
            <input
                type="radio"
                value="weekend"
                checked={dailyProfileToggle === 'weekend'}
                onChange={(e) => setDailyProfileToggle(e.target.value)}
            />
            Weekend
            </label>
        </div>

        <div className="toggleControl">
            <label>
            <input
                type="radio"
                value="summer"
                checked={dailyProfileSeasonToggle === 'summer'}
                onChange={(e) => setDailyProfileSeasonToggle(e.target.value)}
            />
            Summer
            </label>

            <label>
            <input
                type="radio"
                value="autumn"
                checked={dailyProfileSeasonToggle === 'autumn'}
                onChange={(e) => setDailyProfileSeasonToggle(e.target.value)}
            />
            Autumn
            </label>

            <label>
            <input
                type="radio"
                value="winter"
                checked={dailyProfileSeasonToggle === 'winter'}
                onChange={(e) => setDailyProfileSeasonToggle(e.target.value)}
            />
            Winter
            </label>

            <label>
            <input
                type="radio"
                value="spring"
                checked={dailyProfileSeasonToggle === 'spring'}
                onChange={(e) => setDailyProfileSeasonToggle(e.target.value)}
            />
            Spring
            </label>

            <label>
            <input
                type="radio"
                value="current"
                checked={dailyProfileSeasonToggle === 'current'}
                onChange={(e) => setDailyProfileSeasonToggle(e.target.value)}
            />
            Current
            </label>

        </div>

        <canvas id="dailyProfileChart"></canvas>
      </div>

      <div id="usageAveragesBlock" className="chartBox">
      <h3>Annual usage averages</h3>
        <canvas id="usageSummaryChart"></canvas>
      </div>

      <div id="rawDataBlock">
        <h3>Raw reads JSON</h3>
        <pre>
          {JSON.stringify(usageReadings, null, 2)}
        </pre>
      </div>

    </div>
  );
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export default UsageHistory;
