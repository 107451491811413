import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelectedConsumer } from '../App';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';

Chart.register(...registerables);

function startLoadingSpinner(elementId) {
  console.debug(`Starting loading spinner for DIV#${elementId}`)
  // Check if the spinner already exists, and if so, do nothing
  if (document.getElementById('spinner-' + elementId)) return;

  var spinner = document.createElement("div");
  spinner.className = "spinner";
  spinner.innerHTML = '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div>';
  spinner.id = 'spinner-' + elementId;  // Assign a unique id to the spinner

  // Append the spinner to the specified div
  document.getElementById(elementId).appendChild(spinner);
}

function stopLoadingSpinner(elementId) {
  console.debug(`Stopping loading spinner for DIV#${elementId}`)
  var spinner = document.getElementById('spinner-' + elementId);
  
  // If the spinner exists, remove it
  if (spinner) {
      spinner.parentNode.removeChild(spinner);
  }
}

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function BillingHistory({ onConsumerChange }) {
  const { selectedConsumer, selectedServicePoint } = useSelectedConsumer();
  const [billingTransactions, setBillingTransactions] = useState('');
  const [avgDailyCosts, setAvgDailyCosts] = useState({});
  const [billingCostHistoryChart, setBillingCostHistoryChart] = useState(null);
  const [avgDailyUsage, setAvgDailyUsage] = useState({});
  const [billingUsageHistoryChart, setBillingUsageHistoryChart] = useState(null);

  var runCount = 0;

  useEffect(() => {
    runCount++;

    // Initialize chart with empty data
    if (runCount == 1) {
      initialiseBillingCostHistoryChart(avgDailyCosts);
      initialiseBillingUsageHistoryChart(avgDailyUsage);
    }
  }, []);

  useEffect(() => {
    if (selectedConsumer) {
      //Transaction data
      startLoadingSpinner('transactionsBlock');
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/bills/transactions?ignoreOrigin=true&servicePointId=${selectedServicePoint}`) //?accountId=abc123 for filtering by account
        .then(response => {
          //Sort txns by date
          response.data.sort((a, b) => {
            let dateA = new Date(a.executionDateTime);
            let dateB = new Date(b.executionDateTime);
            
            //return dateA - dateB;  // For ascending order
            return dateB - dateA; // Uncomment this for descending order
          });
          setBillingTransactions(response.data);
        })
        .catch(error => {
          console.error('Error fetching billing data:', error);
        })
        .finally(() => {
          stopLoadingSpinner('transactionsBlock');
        });

      //Average daily costs
      startLoadingSpinner('averageCostsBlock');
        axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/bills/avg-daily/cost?ignoreOrigin=true&servicePointId=${selectedServicePoint}`) //?accountId=abc123 for filtering by account
          .then(response => {
            setAvgDailyCosts(response.data);
          })
          .catch(error => {
            setAvgDailyCosts(undefined);
            console.error('Error fetching average daily costs data:', error);
          })
          .finally(() => {
            stopLoadingSpinner('averageCostsBlock');
          });
  
      //Average daily usage
      startLoadingSpinner('averageUsageBlock');
        axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/bills/avg-daily/usage?ignoreOrigin=true&servicePointId=${selectedServicePoint}`) //?accountId=abc123 for filtering by account
          .then(response => {
            setAvgDailyUsage(response.data);
          })
          .catch(error => {
            setAvgDailyCosts(undefined);
            console.error('Error fetching average daily usage data:', error);
          })
          .finally(() => {
            stopLoadingSpinner('averageUsageBlock');
          });
    }
  }, [selectedConsumer, selectedServicePoint]);

  useEffect(() => {
    updateBillingCostHistoryChart(avgDailyCosts);
  }, [avgDailyCosts])

  useEffect(() => {
    updateBillingUsageHistoryChart(avgDailyUsage);
  }, [avgDailyUsage])

  const initialiseBillingCostHistoryChart = (data) => {
    console.debug(`Initialising billing history chart...`);
    // Your chart initialisation code here
    // Use chartRef.current instead of 'myChart'
    // Destroy the existing chart if it exists
    if (billingCostHistoryChart) {
      billingCostHistoryChart.destroy();
    }

    let datasets = [];
    let labels = [];
    let canvas = document.getElementById('averageCostsChart');
    let ctx = canvas.getContext('2d');

    const newChart = new Chart(ctx, {
        type: 'bar',
        data: { labels, datasets },
        options: {
            responsive: true,
            scales: {
                x: { 
                  stacked: true,
                  type: 'time',
                  time: {
                    unit: 'day'
                  }
                 },
                y: {
                    stacked: true,
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                            return '$' + value.toLocaleString();
                        }
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Average daily costs'
                }
            }
        }
  });
    setBillingCostHistoryChart(newChart);

    // Cleanup function
    return () => {
        newChart.destroy();
    };
  };

  const updateBillingCostHistoryChart = (data) => {
    console.debug(`updateBillingCostHistoryChart called`)
    const targetChart = billingCostHistoryChart;

    if (targetChart) {
      if (!data || !data.byDayArray || data.byDayArray.length == 0) {
        targetChart.data.datasets = [];
        targetChart.data.labels = [];
        targetChart.update();
        return;
      }

      let byDayArray = data.byDayArray;

      //Get the data in a format for use by chartjs
      // Extract the dates
      const dates = byDayArray.map(item => item[0]);

      // Extract the keys from the first data object (assuming all objects have the same keys)
      const keys = Object.keys(byDayArray[0][1]);

      // Generate the datasets
      const datasets = keys.map(key => {
        return {
          label: key.charAt(0).toUpperCase() + key.slice(1),  // Capitalize the key for the label
          data: byDayArray.map(item => item[1][key]),
          // Add your color, etc. here
        };
      });

      let netCosts = byDayArray.map(costsForDay => {
          let netCostForDay = 0;
          Object.entries(costsForDay[1]).forEach(([category, categoryCost]) => {
            netCostForDay += categoryCost;
          })
          return netCostForDay;
        }
      );

      
      datasets.push({
          label: 'Net Cost',
          data: netCosts,
          type: 'line',
          fill: false,
//          borderColor: 'rgba(0,0,255,0.5)',  // Choose your own color
//          backgroundColor: 'rgba(0,0,255,0.5)',
          tension: 0.4
      });

      targetChart.data.datasets = datasets;
      targetChart.data.labels = dates;
      targetChart.update();
    }
  };
  
  const initialiseBillingUsageHistoryChart = (data) => {
    console.debug(`Initialising billing usage history chart...`);
    // Your chart initialisation code here
    // Use chartRef.current instead of 'myChart'
    // Destroy the existing chart if it exists
    if (billingUsageHistoryChart) {
      billingUsageHistoryChart.destroy();
    }

    let datasets = [];
    let labels = [];
    let canvas = document.getElementById('averageUsageChart');
    let ctx = canvas.getContext('2d');

    const newChart = new Chart(ctx, {
        type: 'bar',
        data: { labels, datasets },
        options: {
            responsive: true,
            scales: {
                x: { 
                  stacked: true,
                  type: 'time',
                  time: {
                    unit: 'day'
                  }
                 },
                y: {
                    stacked: true,
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                            return value.toFixed(2) + 'kWh';
                        }
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Average daily usage'
                }
            }
        }
  });
    setBillingUsageHistoryChart(newChart);

    // Cleanup function
    return () => {
        newChart.destroy();
    };
  };

  const updateBillingUsageHistoryChart = (data) => {
    console.debug(`updateBillingUsageHistoryChart called`)
    const targetChart = billingUsageHistoryChart;

    if (targetChart) {
      if (!data || !data.byDayArray || data.byDayArray.length == 0) {
        targetChart.data.datasets = [];
        targetChart.data.labels = [];
        targetChart.update();
        return;
      }

      let byDayArray = data.byDayArray;

      //Get the data in a format for use by chartjs
      // Extract the dates
      const dates = byDayArray.map(item => item[0]);

      // Extract the keys from the first data object (assuming all objects have the same keys)
      const keys = Object.keys(byDayArray[0][1]);

      // Generate the datasets
      const datasets = keys.map(key => {
        return {
          label: key.charAt(0).toUpperCase() + key.slice(1),  // Capitalize the key for the label
          data: byDayArray.map(item => item[1][key] * (key == 'export' ? -1 : 1)),
          // Add your color, etc. here
        };
      });

      let netCosts = byDayArray.map(costsForDay => {
          let netCostForDay = 0;
          Object.entries(costsForDay[1]).forEach(([category, categoryCost]) => {
            netCostForDay += (categoryCost * (category == 'export' ? -1 : 1));
          })
          return netCostForDay;
        }
      );

      
      datasets.push({
          label: 'Net Cost',
          data: netCosts,
          type: 'line',
          fill: false,
//          borderColor: 'rgba(0,0,255,0.5)',  // Choose your own color
//          backgroundColor: 'rgba(0,0,255,0.5)',
          tension: 0.4
      });

      targetChart.data.datasets = datasets;
      targetChart.data.labels = dates;
      targetChart.update();
    }
  };
    
  return (
    <div className="contentBody" id='billingBlock'>
      <h2>Billing History - {selectedConsumer}</h2>

      <div id="averageCostsBlock">
        <h3>Average daily costs</h3>
        <canvas id="averageCostsChart"></canvas>
      </div>

      <div id="averageUsageBlock">
        <h3>Average daily usage</h3>
        <canvas id="averageUsageChart"></canvas>
      </div>

      <div id="transactionsBlock">
      <h3>Transactions ledger table</h3>
      <table>
        <thead>
          <th>Date</th>
          <th>Category</th>
          <th>Description</th>
          <th>Amount (inc GST where applicable)</th>
        </thead>
        <tbody>
          {billingTransactions && billingTransactions.map(txn => (
            <tr className={txn.class}>
              <td>{formatDate(txn.executionDateTime)}</td>
              <td>{txn.category}</td>
              <td>{capitalizeFirstLetter(txn.transactionUType)} - {txn[txn.transactionUType].method ? txn[txn.transactionUType].method : ''} {txn[txn.transactionUType].description} {txn[txn.transactionUType].description != txn[txn.transactionUType].timeOfUseType ? txn[txn.transactionUType].timeOfUseType : ''} {txn[txn.transactionUType].startDate && txn[txn.transactionUType].startDate ? formatDate(txn[txn.transactionUType].startDate) + ' - ' + formatDate(txn[txn.transactionUType].endDate) : ''} {txn[txn.transactionUType].invoiceNumber ? 'Inv #' + txn[txn.transactionUType].invoiceNumber : ''}</td>
              <td className="amount">${txn.transactionUType == 'payment' ? Math.abs(Number(txn[txn.transactionUType].amount).toFixed(2)) : (Number(txn[txn.transactionUType].amount) * -1).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3>Transactions JSON</h3>
      <pre>
        {JSON.stringify(billingTransactions, null, 2)}
      </pre>
      </div>

    </div>
  );
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export default BillingHistory;
