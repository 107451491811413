export const awsconfig = {
    Auth: {
        Cognito: {
            region: 'ap-southeast-2', // e.g. 'us-west-2'
            userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
            userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            loginWith: {
                oauth: {
                    domain: process.env.REACT_APP_COGNITO_AUTH_URL,
                    scopes: ['email', 'profile', 'openid'],
                    redirectSignIn: [process.env.REACT_APP_AUTH_CALLBACK_URL],
                    redirectSignOut: [process.env.REACT_APP_AUTH_LOGOUT_URL],
                    responseType: 'code'  // This is for Authorization code grant flow
                }
            }
        }
    }
};
