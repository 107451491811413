import { useContext, useEffect } from 'react';
import { useNavigate, redirect } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { Amplify } from 'aws-amplify';
import { awsconfig } from './AmplifyConfig';
import { getCurrentUser } from 'aws-amplify/auth';
import { handleLogout } from './AuthLogout';

Amplify.configure(awsconfig);

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    console.debug(`From ProtectedRoute: user in context: ${JSON.stringify(user)}`)

    useEffect(() => {
        const isAuthenticated = getCurrentUser()
            .then((argument) => {
                console.debug(`From protectedRoute: isAuthenticated: ${JSON.stringify(argument)}`)
                return true;
            })
            .catch((error) => {
                console.debug(`From protectedRoute: Could not find authenticated user: ${JSON.stringify(error)}. Signing in...`)
                //signInWithRedirect({provider: "Google"});
                navigate("/login")
                return false;
            });
    }, []);

    if (!user) {
        console.log(`Not yet authenticated`)
        return "Not authenticated. Nothing to see."
    }
    else if (!user.isAdmin) {
        console.log(`Not authorised: ${user?.attributes?.email}`)
        handleLogout();
        return "Not authorised. You shouldn't be here..."
    } else {
        return <Component {...rest} />;
    }

};

export default ProtectedRoute;
