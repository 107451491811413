import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

async function getIdToken() {
    try {
        const session = await fetchAuthSession();
        //console.debug(`From axios setup. Session: ${JSON.stringify(session)}`)
        //console.debug(`From axios setup. idToken: ${JSON.stringify(session.tokens.idToken.toString())}`)
        return session.tokens.idToken.toString();
    } catch (error) {
        console.error('Error getting ID token', error);
        return null;
    }
}

export const setupAxiosInterceptors = () => {
    axios.interceptors.request.use(
      async config => {
        //const token = localStorage.getItem('idToken');
        const token = await getIdToken();
        if (token) {
          config.headers.Authorization = `${token}`;
        }
        if (process.env.REACT_APP_DISABLE_CACHE !== "false") {
          config.headers.Pragma = 'no-cache';
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
  